<template>
  <div>
    <template v-if="ready">
      <div :class="inline ? 'd-flex align-items-baseline ' : ''">
        <label :class="inline ? 'mr-2' : ''">{{ label }}</label>

        <template v-if="type !== 'money'">
          <b-input-group>
            <template v-if="prefix" #prepend :disabled="disabled">
              <b-input-group-text>{{ prefix }}</b-input-group-text>
            </template>
            <b-form-input
              :id="id"
              :type="type ? type : 'text'"
              :state="normError"
              :value="value"
              @input="$emit('input', $event), $emit('valueChanged', $event)"
              :disabled="disabled"
              v-mask="mask || null"
            />
          </b-input-group>
        </template>
        <template v-else>
          <div
            class="input-group mb-3"
            :class="[error ? 'border-error' : false]"
            style="margin-bottom: 0 !important"
          >
            <div class="input-group-prepend" v-if="prefix">
              <span
                class="input-group-text"
                :class="[disabled ? 'prepend-disabled' : '']"
                id="basic-addon1"
                >{{ prefix }}</span
              >
            </div>
            <money
              class="form-control"
              v-model="moneyTempValue"
              v-bind="money"
              :disabled="disabled"
              style="border-left: unset !important"
            ></money>
          </div>
        </template>
        <template v-if="error">
          <small class="text-danger" v-if="errorMsg">
            {{ errorMsg }}
          </small>
          <small class="text-danger" v-else>
            O campo {{ label }} é obrigatório.
          </small>
        </template>
      </div>
    </template>
    <template class="mb-3" v-else>
      <div v-if="inline">
        <b-skeleton type="input"></b-skeleton>
      </div>
      <div v-else>
        <b-skeleton class="mb-3" width="120px"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
    </template>
  </div>
</template>

<script>
import { Money } from "v-money";
import crypto from "crypto";
export default {
  props: [
    "value",
    "disabled",
    "label",
    "error",
    "errorMsg",
    "type",
    "ready",
    "mask",
    "prefix",
    "inline",
  ],
  data() {
    return {
      moneyTempValue: "",
      id: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  components: {
    Money,
  },
  watch: {
    moneyTempValue: {
      handler(newValue) {
        this.$emit("input", newValue);
        this.$emit("valueChanged", newValue);
      },
    },
    value: {
      handler(newValue, oldValue) {
        if (this.type == "money" && newValue !== oldValue) {
          this.moneyTempValue = newValue;
        }
      },
    },
  },
  computed: {
    normError() {
      if (this.error) {
        return false;
      }
    },
  },
  mounted() {
    if (this.type == "money") {
      this.moneyTempValue = this.value * 1;
    }
    this.id = crypto.randomBytes(8).toString("hex");
  },
};
</script>

<style lang="scss" scoped>
.prepend-disabled {
  background-color: #dee2e6;
}
.border-error {
  border: 1px solid #dc3545;
  border-radius: 12px;
}
</style>