import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import './mixin'
import Card from '@/components/Card'
import CreateForm from '@/components/CreateForm'
import FormInput from '@/components/FormInput'
import FormText from '@/components/FormText'
import FormSelect from '@/components/FormSelect'
import FormDatepicker from '@/components/FormDatepicker'


Vue.config.productionTip = false

Vue.component('Card', Card)
Vue.component('CreateForm', CreateForm)
Vue.component('FormInput', FormInput)
Vue.component('FormText', FormText)
Vue.component('FormSelect', FormSelect)
Vue.component('FormDatepicker', FormDatepicker)


new Vue({
  router,
  store,  
  render: h => h(App)
}).$mount('#app')
