<template>
  <div>
    <template v-if="ready">
      <label>{{ label }}</label>
      <b-form-textarea
        :id="id"
        :state="normError"
        :value="value"
        @input="$emit('input', $event)"
        :disabled="disabled"
        :cols="cols || '5'"
        :rows="rows || '4'"
        :placeholder="placeholder || ''"
      ></b-form-textarea>
      <b-form-invalid-feedback :id="id">
        <span class="text-small text-red" v-if="errorMsg">
          {{ errorMsg }}
        </span>
        <span class="text-small text-red" v-else>
          O campo {{ label }} é obrigatório.
        </span>
      </b-form-invalid-feedback>
    </template>
    <template class="mb-3" v-else>
      <b-skeleton class="mb-3" width="120px"></b-skeleton>
      <b-skeleton height="65px" type="input"></b-skeleton>
    </template>
  </div>
</template>

<script>
import crypto from "crypto";
export default {
  props: ["value", "disabled", "label", "error", "errorMsg", "ready", "cols", "rows", "placeholder"],
  data() {
    return {      
      id: "",
    };
  },  
  computed: {
    normError() {
      if (this.error) {
        return false;
      }
    },
  },
  mounted() {
    this.id = crypto.randomBytes(8).toString("hex");
  },
};
</script>

<style>
</style>