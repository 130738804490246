import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import modules from './modules';
import Http from 'Http'
import axios from 'axios'

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   key: 'local-db',
//   reducer: state => ({
//     auth: state.auth
//   })
// });

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async get_states({}) {
      
      let data = []

      try {       

        const states = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')       

        let normalized_states = []

        _.forEach(states.data, (estado) => {
          normalized_states.push({ text: estado.sigla, value: estado.sigla })
        })

        data = normalized_states

      } catch ({ response }) {

        console.log('catch')

        throw response

      }

      return data

    },
    async http_post({ }, { url, data, redirect }) {


      try {
        const response = await Http().post(`${url}`, data);

        console.log(response)

        if (response.data.status == 201 || response.data.status == 200) {
          this.state.$app.toastSuccess('Cadastro efetuado com sucesso!')
          if (redirect) {
            this.state.$app.$router.push(redirect);
          }
        } else if (response.data.error) {
          this.state.$app.makeToast({ title: `${response.data.error}`, msg: 'Erro ao tentar gravar', variant: 'danger' })

        }
        return response
      } catch ({ response }) {

        this.state.$app.toastError(`Erro ao enviar ${url}`, response)
      }
    },
    async http_put({ }, { url, data, redirect }) {
      try {
        const response = await Http().put(`${url}`, data);
        console.log('response from put', response)
        if (response.data.status == 201 || response.data.status == 200) {
          this.state.$app.toastSuccess('Alteração efetuada com sucesso!')
          if (redirect) {
            this.state.$app.$router.push(redirect);
          }
        }
        if (response.data.error) {
          this.state.$app.toastError(`${url}`, response.data.error)
        }
        return response
      } catch ({ response }) {
        this.state.$app.toastError(`Erro ao enviar ${url}`, response)
      }
    },

    async http_get({ commit }, { url, vuex }) {

      try {

        const response = await Http().get(`${url}`);

        console.log('from http_get =>', { url, response })

        if (response.data.status == 201 || response.data.status == 200) {

          if (vuex) {

            const data = { state: vuex, data: response.data.payload }

            console.log('setting vuex =>', data)

            commit('SET', data)

          }
        } else if (response.data.length > 0) {
          if (vuex) {

            const data = { state: vuex, data: response.data }

            console.log('setting vuex else if =>', data)

            commit('SET', data)

          }
        } else if (response.data.id) {
          if (vuex) {

            const data = { state: vuex, data: response.data }

            console.log('setting vuex else if =>', data)

            commit('SET', data)

          }
        }

        return response

      } catch (err) {

        console.log('error from catch', err)

        this.state.$app.toastError(`Erro ao enviar ${url}`, err)

      }
    },
    async http_get_financeiro({ commit }, { url, vuex }) {

      try {

        const response = await HttpFinanceiro().get(`${url}`);

        console.log('from http_get =>', { url, response })

        if (response.data.status == 201 || response.data.status == 200) {

          if (vuex) {

            const data = { state: vuex, data: response.data.payload }

            console.log('setting vuex =>', data)

            commit('SET', data)

          }
        } else if (response.data.length > 0) {
          if (vuex) {

            const data = { state: vuex, data: response.data }

            console.log('setting vuex else if =>', data)

            commit('SET', data)

          }
        } else if (response.data.id) {
          if (vuex) {

            const data = { state: vuex, data: response.data }

            console.log('setting vuex else if =>', data)

            commit('SET', data)

          }
        }

        return response

      } catch (err) {

        console.log('error from catch', err)

        this.state.$app.toastError(`Erro ao enviar ${url}`, err)

      }
    },
    async http_post_financeiro({ }, { url, data, redirect }) {


      try {
        const response = await HttpFinanceiro().post(`${url}`, data);

        console.log(response)

        if (response.data.status == 201 || response.data.status == 200) {
          this.state.$app.toastSuccess('Cadastro efetuado com sucesso!')
          if (redirect) {
            this.state.$app.$router.push(redirect);
          }
        } else if (response.data.error) {
          this.state.$app.makeToast({ title: `${response.data.error}`, msg: 'Erro ao tentar gravar', variant: 'danger' })

        }
        return response
      } catch ({ response }) {

        this.state.$app.toastError(`Erro ao enviar ${url}`, response)
      }
    },
  },
  mutations: {
    ["SET"]: (rootState, data) => {
      const [namespace, state] = data.state.split('/')

      rootState[namespace][state] = data.data

    }
  },
  modules,
  //   plugins: [vuexLocal.plugin]
});