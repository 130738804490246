<template>
  <div>
    <template v-if="ready">
      <label>{{ label }}</label>
      <b-form-datepicker
        :class="[error ? 'border-error' : false]"
        label-no-date-selected="Selecione uma data"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
        :value="value"
        @input="$emit('input', $event)"
      ></b-form-datepicker>
      <template v-if="error">
        <small class="text-danger" v-if="errorMsg">
          {{ errorMsg }}
        </small>
        <small class="text-danger" v-else>
          O campo {{ label }} é obrigatório.
        </small>
      </template>
    </template>
    <template v-else>
      <b-skeleton class="mb-3" width="120px"></b-skeleton>
      <b-skeleton type="input"></b-skeleton>
    </template>
  </div>
</template>

<script>
import crypto from "crypto";
export default {
  props: ["value", "disabled", "label", "error", "errorMsg", "ready"],
  computed: {
    normError() {
      if (this.error) {
        return false;
      }
    },
  }  
};
</script>

<style lang="scss" scoped>

.border-error{
  border: 1px solid #dc3545;
}
</style>