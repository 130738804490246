export default
    [
        //List
        {
            path: '/empresas',
            name: 'unities',
            meta: {
                icon: 'file-code',
                title: 'Empresas'
            },
            component: () => import('@/views/unities/list/Index')
        },
        {
            path: '/empresas/adicionar',
            name: 'unities',
            meta: {
                icon: 'file-code',
                title: 'Empresas'
            },
            component: () => import('@/views/unities/list/Add')
        },
        {
            path: '/empresas/editar',
            name: 'unities',
            meta: {
                icon: 'file-code',
                title: 'Empresas'
            },
            component: () => import('@/views/unities/list/Edit')
        },
        {
            path: '/aplicacoes_produtos/:unityId',
            name: 'applications_products.unityId',
            meta: {
                icon: 'file-code',
                title: 'Aplicações/Produtos'
            },
            component: () => import('@/views/unities/applications_products/Index')
        },
        //bank_account
        {
            path: '/contas_bancarias/:unityId',
            name: 'bank_accounts.unityId',
            meta: {
                icon: 'file-code',
                title: 'Contas Bancárias'
            },
            component: () => import('@/views/unities/bank_accounts/Index')
        },
        {
            path: '/contas_bancarias/:unityId/adicionar',
            name: 'bank_accounts.unityId.add',
            meta: {
                icon: 'file-code',
                title: 'Contas Bancárias'
            },
            component: () => import('@/views/unities/bank_accounts/AddView')
        },
        {
            path: '/contas_bancarias/:unityId/editar/:accountId',
            name: 'bank_accounts.unityId.edit',
            meta: {
                icon: 'file-code',
                title: 'Contas Bancárias'
            },
            component: () => import('@/views/unities/bank_accounts/EditView')
        },
        //communication_rate
        {
            path: '/taxas_de_envio/:unityId',
            name: 'communication_rate.unityId',
            meta: {
                icon: 'file-code',
                title: 'Taxas de Envio'
            },
            component: () => import('@/views/unities/communication_rate/Index')
        },
        //payment_rate
        {
            path: '/taxas_de_tipo/:unityId',
            name: 'payment_rate.unityId',
            meta: {
                icon: 'file-code',
                title: 'Tipos de Taxas'
            },
            component: () => import('@/views/unities/payment_rate/Index')
        },
        //transfer_rate
        {
            path: '/taxas_de_transferencia/:unityId',
            name: 'transfer_rate.unityId',
            meta: {
                icon: 'file-code',
                title: 'Taxas de Transferência'
            },
            component: () => import('@/views/unities/transfer_rate/Index')
        },
        {
            path: '/taxas_de_transferencia/:unityId/editar',
            name: 'transfer_rate.unityId',
            meta: {
                icon: 'file-code',
                title: 'Taxas de Transferência'
            },
            component: () => import('@/views/unities/transfer_rate/Edit')
        },
        //usuario_admin
        {
            path: '/usuario_admin/:unityId',
            name: 'user_admin.unityId',
            meta: {
                icon: 'file-code',
                title: 'Usuário Administrador'
            },
            component: () => import('@/views/unities/user_admin/Index')
        },
        {
            path: '/usuario_admin/:unityId/adicionar',
            name: 'user_admin.unityId.add',
            meta: {
                icon: 'file-code',
                title: 'Novo Usuário Administrador'
            },
            component: () => import('@/views/unities/user_admin/AddView')
        },
        {
            path: '/usuario_admin/:unityId/editar/:id',
            name: 'user_admin.unityId.add',
            meta: {
                icon: 'file-code',
                title: 'Novo Usuário Administrador'
            },
            component: () => import('@/views/unities/user_admin/EditView')
        },
        //config
        {
            path: '/configuracao_padrao/:id',
            name: 'config.default',
            meta: {
                icon: 'file-code',
                title: 'Configurações Padrão'
            },
            component: () => import('@/views/unities/config/Index')
        },
    
    ]