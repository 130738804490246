<template>
  <div class="d-flex flex-column">
    <sidebar-menu
      theme="white-theme"
      :hideToggle="true"
      :menu="menu"
      :showChild="false"
      @item-click="onItemClick"
    >
      <div
        slot="header"
        class="
          d-flex
          flex-column
          align-items-center
          justify-content-center          
        "
      >
        <!-- <img class="my-4" :src="getLogo" alt="" style="width: 150px" /> -->
        <img src="https://api.colmeia.io/image/uploads/colmeia/3.svg" alt="" style="width: 100%" />

        <!-- <b-button
          @click="$emit('closeMenu')"
          class="
            menu-close
            icon
            rounded-circle
            mr-3
            d-flex
            align-self-end
            justify-self-end
          "
          variant="outline-primary"
        >
          <fa-icon icon="times" />
        </b-button> -->
        <!-- <img src="@/assets/phieduca.svg" name="icon" width="150px" /> -->
      </div>
      <span slot="dropdown-icon">
        <fa-icon size="xs" icon="chevron-right" />
      </span>
    </sidebar-menu>
  </div>
</template>

<script>
import Cookie from "js-cookie";
export default {
  name: "sidebar", 
  computed: {
    getLogo() {
      const logo = this.$store.state.auth.currentUser.logo;
      if (logo) {
        return logo;
      }
      return false;
    },
    menu() {
      const tempApp = this.$store.state.auth.userMenu;

      let currentApplication = "";

      let currentAppName = "";

      const otherApplications = [];

      let menu = [];

      _.forEach(tempApp, (app) => {
        if (app.id == process.env.VUE_APP_APPLICATION_ID) {
          currentAppName = app.name;
          currentApplication = _.groupBy(app.permission, "menu");
        } else {
          otherApplications.push({ application: app.name, url: app.url });
        }
      });

      const keys = Object.keys(currentApplication);
      _.forEach(keys, (key, idx) => {
        if (idx == 0) {
          menu.push({
            header: true,
            title: currentAppName,
            hiddenOnCollapse: false,
          });
        }
        let item = {                               
          title: key,
          child: [],
          icon: {
            element: "fa-icon",
            class: "text-white",
            attributes: {
              icon: currentApplication[key][0].icon,
              style: "background-color: transparent",
            },
          },
        };
        _.forEach(currentApplication[key], (sub_menu) => {
          if (sub_menu.menu !== sub_menu.sub_menu) {
            item.child.push({
              href: sub_menu.route,
              title: sub_menu.sub_menu,
              icon: "caret-right",
            });
          }
        });
        if (item.child.length > 0) {
          menu.push(item);
        } else {
          delete item.child;
          item.href = currentApplication[key][0].route;
          menu.push(item);
        }
      });

      if (otherApplications.length > 0) {
        _.forEach(otherApplications, (app) => {
          menu.push({
            href: `${app.url}/check/${Cookie.get("refresh_token")}`,
            title: app.application,
            external: true,
          });
        });
      }
      return menu;
    },
  },
  methods: {
    onItemClick(event, item, node) {


      console.log(item)

      event.preventDefault();

      if (!item.child) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      if (item.external) {
        window.location.href = item.href;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
</style>

<style lang="scss">
.v-sidebar-menu {
  background-color: transparent !important;
  position: inherit !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link {
  background-color: transparent !important;
  color: #6c757d !important;
  font-size: 14px;
  font-weight: 300;
}

.v-sidebar-menu .vsm--icon {
  background-color: transparent !important;
  color: #6c757d !important;
  height: 20px !important;
  widows: 20px !important;
}
.vsm--dropdown {
  margin-left: 0.6rem;
}
.v-sidebar-menu .vsm--list {
  background-color: transparent !important;
  margin-left: 1rem;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px #efeeea inset !important;
  box-shadow: 3px 0px 0px 0px #efeeea inset !important;
}
.menu-close {
  display: none !important;
}
@media (max-width: 1024px) {
  .menu-close {
    display: none !important;
    padding: 9px 10px 9px 10px !important;
  }
}
</style>